/*
 * Welcome to your app's main JavaScript file!
 *
 * We recommend including the built version of this JavaScript file
 * (and its CSS file) in your base layout (base.html.twig).
 */

// any CSS you require will output into a single css file (app.css in this case)
require('./style.scss');

// Need jQuery? Install it with "yarn add jquery", then uncomment to require it.
const $ = require('jquery');
const Cookies = require('js-cookie');

require('bootstrap');
require("jquery-ui/ui/widgets/autocomplete");

require('chart.js/dist/chart')

// Trumbowyg (wysiwyg editor)
const icons = require('trumbowyg/dist/ui/icons.svg');
require('trumbowyg/dist/ui/trumbowyg.min.css');
require('trumbowyg/dist/plugins/table/ui/trumbowyg.table.min.css');
require('trumbowyg/dist/trumbowyg.min');
require('trumbowyg/dist/langs/cs.min');
require('trumbowyg/dist/plugins/table/trumbowyg.table.min');
require('./js/trumbowyg.sheets');
require('./js/trumbowyg.uploadLink');
require('./js/map');

$.trumbowyg.svgPath = icons;
// Fixing missing translation for trumbowyg.table plugin
$.trumbowyg.langs.cs['tableDeleteRow'] = 'Smazat řádek';
$.trumbowyg.langs.cs['tableDeleteColumn'] = 'Smazat sloupec';
$.trumbowyg.langs.cs['tableDestroy'] = 'Smazat tabulku';

$(function(){

    $('.js-redirect-select').change(function () {
        const url = this.value;
        if (typeof url === 'string'){
            location.href = url;
        }
    });

    $(".has-data-year").each(function(){

        const yearsMap = new Map();
        const element = this;

        $(this).find('[data-year]').each(function(){
            const year = $(this).data('year').toString();
            if (year !== '') {
                if (yearsMap.has(year)){
                    yearsMap.set(year, yearsMap.get(year) + 1);
                } else {
                    yearsMap.set(year, 1);
                }
            }
        });

        if (yearsMap.size > 0) {
            const sortedYearsMap = new Map([...yearsMap.entries()].sort((a, b) => b[1] - a[1]));

            var span = document.createElement("span");
            span.classList.add('table-dates');
            var text = 'Data pro rok ';
            var index = 0;
            sortedYearsMap.forEach((values,keys)=>{
                const year = keys;
                if (index !== 0){
                    text += ',';
                }
                text += ' '+ year;

                var stars = '';
                for (i = 1; i <= index; i++) {
                    stars += '*';
                }
                text += stars;

                if (index > 0){
                    element.querySelectorAll('[data-year="'+year+'"]').forEach(item => item.append(stars));
                }
                index++;
            })
            span.innerText = text;
            element.parentNode.insertBefore(span, element.nextSibling);
        }
    });

    $(document).on('click','a.clear-form',function(e){
        e.preventDefault();
        const form = $(this).closest('form');
        form.each(function(){
            const input = $(this).find(':input:not(:hidden)');
            input.val('');
            input.change();
        });
    });

    let forms = document.getElementsByClassName("searches-filter-form");
    for (let form of forms) {
        form.addEventListener("submit", event => {
            let form = event.target;

            // let hash = '';

            let formData = new FormData(form);

            if(event.submitter.name === 'export'){
                formData.set("action", 'export');
            }
            // else {
            //     hash = 'sa';
            // }

            if (form.classList.contains('form-empty-field-disabled-on-submit')){
                formData = Array
                    .from(formData)
                    .filter(function([k, v]) { return v });
            }

            let searchData = new URLSearchParams(formData);

            // problem vyhledavani - napred se scrollne, pak teprve refreshne - probliknuti navic; a v paginaci take neni
            // if (hash){
            //     window.location.hash = hash;
            // }
            window.location.search = searchData.toString();


            event.preventDefault();
            event.stopPropagation();
        });
    }

    $(document.body).on('click', '.clickable[data-clickable-url]', (e) => {
        const url = $(e.currentTarget).data('clickable-url');
        const target = $(e.currentTarget).data('clickable-url-target');
        if (target){
            window.open(url, '_blank');
        } else {
            window.location = url;
        }
    });

});

/*const cookie = Cookies.get('reconstruction-modal-shown');
if (cookie !== 'true'){
    Cookies.set('reconstruction-modal-shown', true);

    $modal = $('#underConstructionModal');
    $modal.modal('show');
    $modal.css('zIndex', '1050');
}*/

// $(function () {
//     if (document.querySelector('body.homepage') === null) {
//         return;
//     }
    // function populateOptions(select, data){
    //     select
    //         .empty()
    //         .append()
    //         .append('<option></option>')
    //     ;
    //     for (const [key, value] of data){
    //         select.append('<option value="'+key+'">'+value+'</option>')
    //     }
    // }
    // const modal = $('#grant-modal-guide-modal');
    // const beneficiaryUrl = modal.data('beneficiary');
    // const sourceUrl = modal.data('department');
    //
    // const beneficiarySelect = modal.find('#grant-opportunities-beneficiary');
    // const sourceSelect = modal.find('#grant-opportunities-department');
    // $(".modal-dialog button[data-dismiss='modal']").click(function(e) {
    //     e.preventDefault();
    //     modal.hide();
    //     populateOptions(beneficiarySelect,[]);
    //     populateOptions(sourceSelect,[]);
    //     beneficiarySelect.prop("disabled", true);
    //     sourceSelect.prop("disabled", true);
    // });
    // display modal and fill beneficiary
    // $(document).on("click", "a#grant-modal-guide-link" , function(e) {
    //     e.preventDefault();
    //
    //     modal.show();
    //
    //     $.ajax({
    //         url: beneficiaryUrl,
    //         success: function(data){
    //             populateOptions(beneficiarySelect, Object.entries(data));
    //             beneficiarySelect.prop("disabled", false);
    //         },
    //     });
    // });
    // $(document).on("change", "#grant-opportunities-beneficiary" , function(e) {
    //     sourceSelect.prop("disabled", true);
    //     $.ajax({
    //         url: sourceUrl+'/'+this.value,
    //         success: function(data){
    //             populateOptions(sourceSelect, Object.entries(data));
    //             sourceSelect.prop("disabled", false);
    //         },
    //     });
    // });
    // $(document).on("change", "#grant-opportunities-department" , function(e) {
    //     let url = new URL(modal.data('grant-opportunities'), location.href);
    //     url.searchParams.set('beneficiary', beneficiarySelect.find(':selected').text());
    //     url.searchParams.set('department', sourceSelect.find(':selected').text());
    //     window.location.href = url.toString();
    // });
// });

// statisticsDataChapter
$(function(){

    function hide(element){
        element.hide();
        element.prop('disabled', 'disabled');
    }

    function show(element){
        element.show();
        element.prop('disabled', false);
    }

    const nuts2 = $('#nutsUrovneSelect #nuts2');
    const nuts3 = $('#nutsUrovneSelect #nuts3');
    const nuts4 = $('#nutsUrovneSelect #nuts4');
    const orp = $('#nutsUrovneSelect #orp');
    const village = $('#nutsUrovneSelect #village');

    $('#nutsUrovneSelect #uroven').change(function() {
        const value = $(this).val();

        hide(nuts2);
        hide(nuts3);
        hide(nuts4);
        hide(orp);
        hide(village);

        switch (value){
            case '1188':
                break;
            case '1189':
                show(nuts2);
                break;
            case '100':
                show(nuts3);
                break;
            case '101':
                show(nuts4);
                break;
            case '65':
                show(orp);
                break;
            case '43':
                show(village);
                break;
            default:
                console.log(value);
                break;
        }
    });
});


// pagination
$(function() {
    $('.pagination-section ul.pagination li:not(.disabled)')
        .css('cursor', 'pointer')
        .click(function() {
            window.location = $('a', this).attr('href');
            return false;
        });
    $('.pagination-section ul.pagination li.disabled a')
        .css('cursor', 'default')
        .click(function(e) {
            e.preventDefault();
        });
});

// Suggestions
$(function() {
    $("*[data-searchurl]").each(function(){
        const url = $(this).attr('data-searchurl');
        $(this).autocomplete({
            source: url,
            minLength: 3,
            select: function( event, ui ) {
                if(ui.item.link) {
                    window.location.href = ui.item.link;
                }
            },
            position: $(this).hasClass('locate-right')
                ? { my : 'right top', at: 'right bottom', of: '.topnav'}
                : {},
            messages: {
                noResults: '',
            },
        });
    });
});

// sortable pagination header
$(function() {
   $("a.pagination-header-with-order").click((e) => {
       e.preventDefault();
       const currentUrl = new URL(location.href);
       const params = new URLSearchParams();
       params.append('search', currentUrl.searchParams.get('search'));
       params.append('direction', currentUrl.searchParams.get('direction') === 'asc' ? 'desc' : 'asc');
       params.append('by', e.currentTarget.dataset.sort_by);

       window.location.href = location.pathname +'?'+ params.toString();
   });
});

$(function() {
    $(".autocomplete-inline").each(function(){
        const optionsStr = $(this).data('options');
        const options = optionsStr.split('~');

        var accentMap = {
            "ě": "e",
            "š": "s",
            "č": "c",
            "ř": "r",
            "ž": "z",
            "ý": "y",
            "á": "a",
            "í": "i",
            "é": "e",
            "ú": "u",
            "ů": "u"
        };

        var normalize = function( term ) {
            var ret = "";
            var tmpTerm = term.toLowerCase();
            for ( var i = 0; i < tmpTerm.length; i++ ) {
                ret += accentMap[ tmpTerm.charAt(i) ] || tmpTerm.charAt(i);
            }
            return ret;
        };

        $(this).autocomplete({
            source: function(request, response) {
                var matcher = new RegExp( $.ui.autocomplete.escapeRegex( request.term ), "i" );

                var results = ( $.grep( options, function( value ) {
                    value = value.label || value.value || value;
                    return matcher.test( value ) || matcher.test( normalize( value ) );
                }) );

                response(results.slice(0, 10));
            }
        });
    });
});

// modal list
$(function () {
        var modal;
        var modalGraphs = [];

        document.addEventListener('keydown', function(e) {
            if (e.key === 'Escape'){
                modalClose();
            }
        });

        $(document).on("click", ".modal-page .close, .modal-content .close" , function(e) {
            e.preventDefault();
            modalClose();
        });

        $(document).on("click", "a.list-modal-open" , function(e) {
            e.preventDefault();
            const id = $(this).data('id');
            modal = $('#list-modal-'+id);
            modal.show();
            modal.trigger("modal.shown");

            detailModalDisplayed(modal);
        });


        function modalClose() {
            if (modal){
                modal.hide();
                for (const graph of modalGraphs ){
                    graph.destroy();
                }
                modalGraphs = [];
            }
        }


        // When the user clicks anywhere outside of the modal, close it
        window.onclick = function (event) {
            if (event.target.classList.contains('modal')) {
                modalClose();
            }
        }

        function detailModalDisplayed(element){
            euGraph(element);
            grantGraph(element);
        }

        function euGraph(element){
            const graphs = $(element).find('.eu-project-financial-graph');

            const colorsPalette = {
                euContribution: '#082E88',
                nationalPublicSources: '#018e42',
                nationalPrivateSesources: '#FF9F1C',
                privateSources: '#ba1828',
                otherSources: '#B7FDFE',
            };

            const labelTranslations = {
                euContribution: 'Příspěvek EU',
                nationalPublicSources: 'Národní veřejné zdroje',
                nationalPrivateSesources: 'Soukromé národní zdroje',
                privateSources: 'Soukromé zdroje',
                otherSources: 'Další zdroje',
            };

            for (const graph of graphs ){
                modalGraphs.push(displayGraph(graph, labelTranslations, colorsPalette));
            }
        }

        function grantGraph(element){
            const graphs = $(element).find('.grant-financial-graph');
            
            const colorsPalette = {
                granted: '#ba1828',
                gained: '#082e88',
            };

            const labelTranslations = {
                granted: 'Rozhodnutá částka - zbývá',
                gained: 'Čerpáno',
            };

            for (const graph of graphs ){
                modalGraphs.push(displayGraph(graph, labelTranslations, colorsPalette));
            }

        }

    function displayGraph(graph, labelTranslations, colorsPalette) {
        const dataSource = JSON.parse(graph.dataset.sources)

        const labels = Object.keys(dataSource).map(key => labelTranslations[key]);
        const colors = Object.keys(dataSource).map(key => colorsPalette[key]);

        const data = {
            labels: labels,
            datasets: [
                {
                    data: Object.values(dataSource),
                    backgroundColor: colors,
                }
            ]
        };

        const config = {
            type: 'pie',
            data: data,
            options: {
                responsive: false,
                plugins: {
                    legend: {
                        // position: 'left',
                        display: false,
                    },
                    title: {
                        display: false,
                    }
                },
                animation: {
                    duration: 0
                }
            },
        };

        return new Chart(graph, config);
    }

    $(document).on("modal.shown", function (event) {
        $(event.target).find('.lazy-load-modal-iframe').each(function(){
            const iframe = $(this);
            if (!iframe.attr('src')){
                iframe.attr('src', iframe.data('src'));
            }
        });
    });

        $('.modal.open-on-load').each(function (idx, el) {
            modal = $(el);
            const cookieId = modal.attr('id')+'-shown'
            const cookie = Cookies.get(cookieId);
            if (cookie !== 'true'){
                Cookies.set(cookieId, true, { sameSite: 'strict' });
                modal.show();
            }
        });
});

$(function (){
    const options = ['2007-2013','2014-2020'];
    const $target = $('body.search_eu_projects #name-program');

    $('body.search_eu_projects #year-program').change(e => {
        const selectedValue = e.currentTarget.value;

        $target.children('option').each((idx, el) => {
            el.style.display = "block";
        });

        if (selectedValue){
            options.filter(option => option !== selectedValue).forEach(option => {
                $target.children(`option[data-period="${option}"]`).each((idx, el) => {
                        el.style.display = "none";
                        el.selected = false;
                })
            });
        }
    });

    $('body.search_eu_projects #year-program').change();
})

// Trumbowyg (wysiwyg editor)
$(() => {
    const pageEditorLayout = '#page-editor-layout';
    const pageEditor = '#page-editor';
    const pageContent = '#page-content';
    const pageEdit = '.page-edit';
    const pageSave = '.page-save';
    const pageCancel = '.page-cancel';
    $(pageEditor).trumbowyg({
        btns: [
            ['bold', 'italic', 'underline', 'unorderedList', 'orderedList', 'h1', 'h2', 'h3', 'link', 'sheets', 'table', 'uploadLink', '|', 'viewHTML']
        ],
        lang: 'cs',
        autogrow: true,
        plugins: {
            uploadLink: {
                serverPath: '/cs/admin/wysiwyg/upload',
                fileFieldName: 'file'
            }
        }
    });
    // note: trumbowyg "RISY js table insert" is managed by custom plugin "sheets" in trumbowyg.sheets.js

    $(pageEdit).click(function(e){
        e.preventDefault();
        $(pageSave).show();
        $(pageCancel).show();
        $(pageEditorLayout).removeClass('page-editor-hidden');
        $(pageEdit).hide();
        $(pageContent).hide();
    });

    function finishEdit() {
        $(pageSave).hide();
        $(pageCancel).hide();
        $(pageEditorLayout).addClass('page-editor-hidden');
        $(pageEdit).show();
        $(pageContent).show();
    }

    $(pageCancel).click(function(e){
        e.preventDefault();
        finishEdit();
    });

    function reloadPageContent() {
        $("#page-content .sheet-ref").each(function(){
            const sheetUrl = $(this).attr('data-sheet');
            const self = this;
            $.ajax({
                url: sheetUrl,
                success: function(data){
                    $(self).html($(data).find('div.table-grid')[0]);
                    $(self).show();
                },
            });
        });
    }

    $(pageSave).click(function(e){
        e.preventDefault();
        const pageId = $(pageEditor).attr('data-page-id');
        const saveUrl = $(pageEditor).attr('data-page-url');
        const content = $(pageEditor).trumbowyg('html');
        $.post(saveUrl, { id: pageId, content: content }, function(data) {
            finishEdit();
            $("#page-content").html(content);
            reloadPageContent();
        }, "json");
    });

    reloadPageContent();
});

$(() => {
    const subcategorySelect = document.getElementById('project-intentions-subcategories');
    const categorySelect = document.getElementById('project-intentions-main-categories');

    if (categorySelect === null ){
        return;
    }

    const categoryData = JSON.parse(subcategorySelect.dataset.source);

    categorySelect.addEventListener('change', (event) => {
        const categoryId = event.target.value;
        if (categoryData[categoryId] !== undefined){
            let content = '<option value=""></option>';
            for (const subcategoryId in categoryData[categoryId]){
                content += `<option value="${ subcategoryId }">${ categoryData[categoryId][subcategoryId] }</option>`;
            }
            subcategorySelect.innerHTML = content;
            // enable?
        } else {
            // disable?
            subcategorySelect.innerHTML = '';
        }
    });
});

$(function (){
    // run only on village_search_detail page
    if (document.querySelector('body.village_search_detail') === null
        && document.querySelector('body.village_search_detail_subArea') === null
    ){
        return;
    }

    const proTab =  document.querySelector('#pro-tab-nav-item');
    if (proTab) {
        const czsoCode = proTab.dataset.id;
        fetch("https://www.obcepro.cz/program-rozvoje-obce-zuj-" + czsoCode + ".json")
            .then(function (response) {
                return response.json();
            })
            .then(function (response) {
                if (response.status !== 'success' || response.documents.lenth < 1) {
                    return;
                }
                const areaName = proTab.dataset.name;
                let innerHtml = '';
                for (const doc of response.documents) {
                    innerHtml += `
                          <img alt="pdf" src="/build/images/new/icon-pdf.png" class="icon-pdf" style="display: inline-block;" >
                          <a href="${doc.url}" target="_blank">${ doc.name ? doc.name : `${areaName} (ID: ${doc.id})`}</a>
                          <br>
                        `;
                }
                innerHtml += `<div class="mb-2">`;
                const docDiv = document.querySelector('#pills-pro-div-content');
                docDiv.innerHTML = innerHtml;

                document.querySelector('#pro-tab-nav-item').style.display = 'list-item';
            })
            .catch(function (arg) {
            });
    }

    const loadAjaxTab = function (div, link = null){
        let $div = $(div)
        $div.addClass('loading');
        let url = link ?? $div.data('url');
        $.ajax({
            type: "get",
            url: url
        }).done(function(data){  // .done(), jqXHR.fail(), and jqXHR.always()
            $div.find('.pagination-content').html(data);
        }).always(function () {
            $div.removeClass('loading');
        });
    }

    $(document).on('click','ul.ajax-link a',function(event){
        event.preventDefault();
        event.stopPropagation();

        let a = $(event.target);

        const url = a.attr('href');

        let div = $('#verejna-sprava-category-detail');
        loadAjaxTab(div, url);
    });

    // pagination on ajax form
    $(document).on('click','.ajax-pagination ul.pagination',function(event){
        event.preventDefault();
        event.stopPropagation();

        let a = $(event.target);
        if (a.is('li')){
            a = a.find('a');
        }
        if (a.is('img')){
            a = a.parent();
        }
        if (a.parent().hasClass('disabled')){
            return;
        }

        const url = a.attr('href');

        let div = a.closest('div.ajax-pagination');
        loadAjaxTab(div, url);
    });

    $(document).on('click','.ajax-pagination form button[type="submit"]',function(event){
        event.preventDefault();
        event.stopPropagation();

        let $btn = $(event.currentTarget);
        let formData = new URLSearchParams($btn.closest('form').first().serialize());
        let div = $btn.closest('div.ajax-pagination');

        let urlData = $(div).data(url).url;
        let url = new URL(urlData, window.location.href);
        formData.forEach(function ($value, $key) {
            url.searchParams.set($key, $value);
        })

        loadAjaxTab(div, url.toString());
        return true;
    });

    let tabObserver = new IntersectionObserver(
        function(entries, observer){
            entries.forEach(function (entry){
                if (entry.isIntersecting) {
                    observer.unobserve(entry.target);
                    loadAjaxTab(entry.target);
                }
            });
        }
    );
    function lazyloadAjaxTab(div){
        tabObserver.observe(div);
    }

    $('div.ajax-pagination').each(function(index, value) {
        lazyloadAjaxTab(value);
    });

    let villageBudgetSelect = $('#village-budget-season-choice');
    villageBudgetSelect.change(function (){
        let seasonId = this.value;
        document.querySelectorAll('.village-budget-season-detail:not(.d-none)')
            .forEach((div) => div.classList.add('d-none'));
        const villageBudgetSeasonDetail = document.getElementById('village-budget-season-detail-'+seasonId);
        if (villageBudgetSeasonDetail !== null){
            villageBudgetSeasonDetail.classList.remove('d-none');
        }

        // const frame = document.getElementById('municipal-graph-frame');
        // let src = frame.dataset.src;
        // src += '&obdobi='+seasonId
        // console.log(src);
        // frame.src = src;
    });
    villageBudgetSelect.change();
});

$(function (){
    // if ($('#eu-grant-opportunities-search-form').length === 0 ){
    //     return;
    // }
    // kdyz se nacte stranka, tenhle form jeste neni nacteny

    function createOption(select, value, label){
        let el = document.createElement("option");
        el.textContent = label;
        el.value = value;
        select.append(el);
    }

    function clearOptions(select){
        select.html('');
        createOption(select, '', '');
        select.prop('disabled', true);
    }

    function populateOptions(select, items){
        items.forEach(function (item) {
            createOption(select, item.id, item.label)
        })
        select.prop('disabled', false);
    }

    $(document).on("change", "#eu-grant-opportunities-category" , function (item) {
        const selectedId = item.target.value;
        const subcategories = $('#eu-grant-opportunities-subcategory');
        const data = subcategories.data('options');
        const parent = data[selectedId];
        clearOptions(subcategories);

        if (parent){
            populateOptions(subcategories, parent.children);
        }
    });
});

$(function (){
    if ($('body.search_grant_opportunities').length === 0){
        return;
    }

    const beneficiarySelect = $('#grant-opportunities-beneficiary');
    const villageWrapper = $('#grant-opportunities-village-wrapper');
    const villageSelect = $('#grant-opportunities-village');

    beneficiarySelect.change(function () {

        if (this.options[this.selectedIndex].text === 'Obec'){
            villageWrapper.show();
            villageSelect.removeAttr('disabled');
        } else {
            villageWrapper.hide();
            villageSelect.attr('disabled','disabled');
        }
    });
    beneficiarySelect.change();
});

