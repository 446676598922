(function ($) {
    'use strict';

    $.extend(true, $.trumbowyg, {
        langs: {
            cs: {
                sheets: 'Vložit tabulku z URL',
            }
        },
        plugins: {
            sheets: {
                init: function (trumbowyg) {
                    // Adding button to editor menu
                    trumbowyg.addBtnDef('sheets', {
                        ico: 'table',
                        fn: function () {
                            // This function is called when user clicks on button in editor menu
                            trumbowyg.openModalInsert(
                                'Vložit tabulku se statistickými daty',
                                {
                                    url: {
                                        label: 'URL adresa tabulky',
                                        name: 'url',
                                        value: 'https://www.risy.cz/',
                                        type: 'text',
                                        required: true,
                                    },
                                },
                                function (values) {
                                    // This function is called when user submit modal
                                    const insert = $(`<div class="sheet-ref" data-sheet="${values.url}">TABULKA: ${values.url}</div>`)[0];
                                    trumbowyg.range.deleteContents();
                                    trumbowyg.range.insertNode(insert);
                                    return true;
                                }
                            );
                        }
                    });
                }
            }
        }
    });
})(jQuery);
